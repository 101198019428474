import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { confirmPasswordReset } from '../client/authService';

const PasswordResetConfirm = () => {
    const [newPassword1, setNewPassword1] = useState('');
    const [newPassword2, setNewPassword2] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const { uid, token } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        console.log('UID:', uid);
        console.log('Token:', token);
        if (!uid || !token) {
            setError('Invalid password reset link.');
        }
    }, [uid, token]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage('');
        setError('');

        if (newPassword1 !== newPassword2) {
            setError('Passwords do not match.');
            return;
        }

        try {
            const response = await confirmPasswordReset(uid, token, newPassword1, newPassword2);
            setMessage(response.message);
            setTimeout(() => navigate('/login'), 3000);
        } catch (err) {
            setError(err.response?.data?.new_password2?.[0] || 'An error occurred. Please try again.');
        }
    };

    return (
        <>
            <Helmet>
                <title>Confirm Password Reset | {process.env.REACT_APP_SITE_NAME}</title>
                <meta name="description" content="Confirm your password reset on our platform." />
                <meta name="keywords" content="password reset, confirm password, security" />
                <link rel="canonical" href={`https://${process.env.REACT_APP_SITE_DOMAIN}/password-reset/confirm`} />
            </Helmet>
            <div className="bg-base-200 flex items-center justify-center min-h-screen">
                <div className="card w-96 bg-base-100 shadow-xl">
                    <div className="card-body">
                        <h2 className="card-title text-2xl font-bold mb-6">Set New Password</h2>
                        {message && <div className="alert alert-success">{message}</div>}
                        {error && <div className="alert alert-error">{error}</div>}
                        {!uid || !token ? (
                            <div className="alert alert-warning">Missing UID or token in URL.</div>
                        ) : (
                            <form onSubmit={handleSubmit}>
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">New Password</span>
                                    </label>
                                    <label className="input input-bordered flex items-center gap-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4 opacity-70"><path fillRule="evenodd" d="M14 6a4 4 0 0 1-4.899 3.899l-1.955 1.955a.5.5 0 0 1-.353.146H5v1.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2.293a.5.5 0 0 1 .146-.353l3.955-3.955A4 4 0 1 1 14 6Zm-4-2a.75.75 0 0 0 0 1.5.5.5 0 0 1 .5.5.75.75 0 0 0 1.5 0 2 2 0 0 0-2-2Z" clipRule="evenodd" /></svg>
                                        <input
                                            type="password"
                                            className="grow"
                                            placeholder="Enter new password"
                                            value={newPassword1}
                                            onChange={(e) => setNewPassword1(e.target.value)}
                                            required
                                        />
                                    </label>
                                </div>
                                <div className="form-control mt-4">
                                    <label className="label">
                                        <span className="label-text">Confirm New Password</span>
                                    </label>
                                    <label className="input input-bordered flex items-center gap-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4 opacity-70"><path fillRule="evenodd" d="M14 6a4 4 0 0 1-4.899 3.899l-1.955 1.955a.5.5 0 0 1-.353.146H5v1.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2.293a.5.5 0 0 1 .146-.353l3.955-3.955A4 4 0 1 1 14 6Zm-4-2a.75.75 0 0 0 0 1.5.5.5 0 0 1 .5.5.75.75 0 0 0 1.5 0 2 2 0 0 0-2-2Z" clipRule="evenodd" /></svg>
                                        <input
                                            type="password"
                                            className="grow"
                                            placeholder="Confirm new password"
                                            value={newPassword2}
                                            onChange={(e) => setNewPassword2(e.target.value)}
                                            required
                                        />
                                    </label>
                                </div>
                                <div className="form-control mt-6">
                                    <button type="submit" className="btn btn-primary">
                                        Reset Password
                                    </button>
                                </div>
                            </form>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default PasswordResetConfirm;
