import React from 'react';
import { Helmet } from 'react-helmet-async';
import LandingPage from '../components/LandingPage';

const Home = () => {
    return (
        <>
            <Helmet>
                <title>{`${process.env.REACT_APP_SITE_NAME}`}</title>
                <meta name="description" content="A production-ready Django-React foundation with authentication, payments, and infrastructure ready to go — so you can focus on what makes your product unique from day one!" />
                <meta name="keywords" content="Django Shipfast, Django boilerplate, Django starter, Django template, Django, React" />
                <link rel="canonical" href={`https://${process.env.REACT_APP_SITE_DOMAIN}`} />

                {/* Open Graph tags */}
                <meta property="og:type" content="website" />
                <meta property="og:title" content={`${process.env.REACT_APP_SITE_NAME} - Ship Your Django+React Production Faster`} />
                <meta property="og:description" content="A production-ready Django-React foundation with authentication, payments, and infrastructure ready to go — so you can focus on what makes your product unique from day one!" />
                <meta property="og:url" content={`https://${process.env.REACT_APP_SITE_DOMAIN}`} />
                <meta property="og:image" content={`https://${process.env.REACT_APP_SITE_DOMAIN}/link-preview.png`} />

                {/* Twitter Card tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={`${process.env.REACT_APP_SITE_NAME} - Ship Your Django App Faster`} />
                <meta name="twitter:description" content="A production-ready Django-React foundation with authentication, payments, and infrastructure ready to go — so you can focus on what makes your product unique from day one!" />
                <meta name="twitter:url" content={`https://${process.env.REACT_APP_SITE_DOMAIN}`} />
                <meta name="twitter:image" content={`https://${process.env.REACT_APP_SITE_DOMAIN}/link-preview.png`} />
            </Helmet>
            <main>
                <LandingPage />
            </main>
        </>
    );
};

export default Home;
