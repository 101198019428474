import React, { useEffect, useState } from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { api } from '../client/apiService';

const PaymentResult = () => {
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState('open');

  useEffect(() => {
    const sessionId = searchParams.get('session_id');
    if (sessionId) {
      api.get(`checkout-session-status/${sessionId}/`)
        .then(response => {
          setStatus(response.data.status);
        })
        .catch(error => {
          console.error('Error retrieving session status:', error);
          setStatus('error');
        });
    }
  }, [searchParams]);

  return (
    <>
      <Helmet>
        <title>Payment Result | {process.env.REACT_APP_SITE_NAME}</title>
        <meta name="description" content="View your Django ShipFast payment result and transaction status. Get confirmation of your successful purchase or troubleshoot any payment issues." />
        <meta name="keywords" content="Django Shipfast payment result, transaction status, payment confirmation, purchase status" />
        <link rel="canonical" href={`https://${process.env.REACT_APP_SITE_DOMAIN}/payment-result`} />
      </Helmet>
      <div className="container mx-auto px-4 py-16 text-center">
        <h1 className="text-4xl font-bold mb-12">Payment Result</h1>
        {status === 'complete' ? (
          <>
            <p className="text-green-500 text-xl mb-8">Your payment was successful!</p>
            <p className="mb-8">Thank you for your purchase. Your subscription has been activated.</p>
            <Link to="/" className="btn btn-primary">
              Go to Dashboard
            </Link>
          </>
        ) : status === 'open' ? (
          <p className="text-yellow-500">Your payment is still processing...</p>
        ) : (
          <p className="text-red-500">There was an issue with your payment. Please try again.</p>
        )}
      </div>
    </>
  );
};

export default PaymentResult;
