import { api, fetchCSRFToken } from './apiService';
import { subscribeUser } from './notificationService';

export const handleLogoutOnTokenExpiry = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    window.location.href = '/';
};

export const login = async (emailOrUsername, password) => {
    try {
        await fetchCSRFToken();
        const isEmail = emailOrUsername.includes('@');
        const loginData = isEmail ? { email: emailOrUsername, password } : { username: emailOrUsername, password };
        const response = await api.post('auth/login/', loginData);

        if (response.data.access) {
            localStorage.setItem('access_token', response.data.access);
        }
        if (response.data.refresh) {
            localStorage.setItem('refresh_token', response.data.refresh);
        }

        const userProfileData = await getUserProfile();
        return { userData: userProfileData, message: "Login successful." };
    } catch (error) {
        console.error('Login error:', error.response?.data || error.message);
        throw new Error(error.response?.data?.detail || 'Login failed. Please try again.');
    }
};

export const logout = async () => {
    try {
        await fetchCSRFToken();
        await api.post('auth/logout/');
    } catch (error) {
        console.error('Logout error:', error.response?.data || error.message);
    } finally {
        handleLogoutOnTokenExpiry();
    }
};

export const signup = async (email, password1, password2) => {
    try {
        const response = await api.post('auth/registration/', {
            email,
            password1,
            password2
        });
        console.log("Signup response:", response);

        if (response.status === 201) {
            if (response.data.user) {
                const userData = {
                    email: response.data.user.email,
                    username: response.data.user.username
                };
                localStorage.setItem('user', JSON.stringify(userData));

                // Save the access token if it's provided in the response
                if (response.data.access_token) {
                    localStorage.setItem('access_token', response.data.access_token);
                }

                return { userData: userData, message: "User created." };
            } else {
                return { userData: null, message: response.data.detail + " Please check your email to verify your account." };
            }
        }
    } catch (error) {
        console.error('Signup error response:', error.response?.data || error.message);
        throw error;
    }
};

export const getCurrentUser = () => {
    const userStr = localStorage.getItem('user');
    if (userStr) return JSON.parse(userStr);
    return null;
};

export const getUserProfile = async () => {
    try {
        const response = await api.get('user/profile/');
        if (!response.data.is_subscribed_to_notifications) {
            console.log("To subscribe to notifications");
            await subscribeUser();
            response.data.is_subscribed_to_notifications = true;
        }
        localStorage.setItem('user', JSON.stringify(response.data));
        console.log("User profile data:", response.data);
        return response.data;
    } catch (error) {
        console.error('Error getting user profile:', error.response?.data || error.message);
        throw error;
    }
};

export const updateUserProfile = async (profileData) => {
    try {
        await fetchCSRFToken();
        const response = await api.patch('user/profile/', profileData);
        return response.data;
    } catch (error) {
        console.error('Error updating user profile:', error.response?.data || error.message);
        throw error;
    }
};

export const initiatePasswordReset = async (email) => {
    try {
        await fetchCSRFToken(); // Fetch CSRF token before initiating password reset
        console.log("Initiating password reset for email:", email);
        const response = await api.post('auth/password/reset/', { email });
        console.log("Password reset initiation response:", response);
        return { message: "Password reset email sent. Please check your inbox." };
    } catch (error) {
        console.error('Password reset initiation error:', error.response?.data || error.message);
        throw error;
    }
};

export const confirmPasswordReset = async (uid, token, new_password1, new_password2) => {
    try {
        const response = await api.post('auth/password/reset/confirm/', {
            uid,
            token,
            new_password1,
            new_password2
        });
        console.log("Password reset confirmation response:", response);
        return { message: "Password has been reset successfully." };
    } catch (error) {
        console.error('Password reset confirmation error:', error.response?.data || error.message);
        throw error;
    }
};

export const changePassword = async (passwordData) => {
    try {
        await fetchCSRFToken(); // Fetch CSRF token before changing password
        const response = await api.post('auth/password/change/', passwordData);
        console.log("Password change response:", response);
        return response.data;
    } catch (error) {
        console.error('Error changing password:', error.response?.data || error.message);
        throw error;
    }
};

export const hasValidToken = () => {
    const token = localStorage.getItem('refresh_token');
    return !!token;
};
