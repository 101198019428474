import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { getUserProfile, updateUserProfile } from '../client/authService';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { getUserAllPurchases, unsubscribe } from '../client/paymentService';
import { format } from 'date-fns';

const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    try {
        return format(new Date(dateString), 'PPP');
    } catch (error) {
        console.error('Error formatting date:', error);
        return 'Invalid date';
    }
};

const Profile = () => {
    const { user, setUser } = useAuth();
    const [profile, setProfile] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState({});
    const [error, setError] = useState(null);
    const [usernameError, setUsernameError] = useState('');
    const [purchases, setPurchases] = useState({ subscriptions: [], one_time_purchases: [] });
    const [loading, setLoading] = useState(true);
    const [subscriptionToCancel, setSubscriptionToCancel] = useState(null);

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const data = await getUserProfile();
                setProfile(data);
                setFormData(data);
            } catch (error) {
                console.error('Error fetching profile:', error);
                setError('Failed to load profile. Please try again later.');
            }
        };
        fetchProfile();
    }, []);

    useEffect(() => {
        fetchPurchases();
    }, []);

    const fetchPurchases = async () => {
        try {
            setLoading(true);
            const data = await getUserAllPurchases();
            setPurchases({
                subscriptions: data.subscriptions || [],
                one_time_purchases: data.purchases || []
            });
            setError(null);
        } catch (err) {
            setError('Failed to load purchases');
            console.error('Error fetching purchases:', err);
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'username') {
            // Validate username: only allow letters, numbers, and underscores, and limit to 20 characters
            const sanitizedValue = value.replace(/[^a-zA-Z0-9_]/g, '').slice(0, 20);
            setFormData({ ...formData, [name]: sanitizedValue });

            if (value !== sanitizedValue) {
                setUsernameError('Username can only contain letters, numbers, and underscores, and must be 20 characters or less.');
            } else {
                setUsernameError('');
            }
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);

        // Additional username validation before submission
        if (formData.username && !/^[a-zA-Z0-9_]{1,20}$/.test(formData.username)) {
            setError('Username can only contain letters, numbers, and underscores, and must be 1-20 characters long.');
            return;
        }

        try {
            const updatedProfile = await updateUserProfile(formData);
            setProfile(updatedProfile);
            // Update the user in AuthContext
            setUser({
                ...user,
                username: updatedProfile.username
            });
            setIsEditing(false);
        } catch (error) {
            console.error('Error updating profile:', error);
            if (error.response && error.response.data) {
                const errorMessages = Object.values(error.response.data).flat();
                setError(
                    <ul className="list-disc list-inside">
                        {errorMessages.map((message, index) => (
                            <li key={index}>{message}</li>
                        ))}
                    </ul>
                );
            } else {
                setError('Failed to update profile. Please try again.');
            }
        }
    };

    const openCancelModal = (subscription) => {
        setSubscriptionToCancel(subscription);
        document.getElementById('cancel_modal').showModal();
    };

    const handleCancelSubscription = async () => {
        if (!subscriptionToCancel) return;

        try {
            setLoading(true);
            await unsubscribe(subscriptionToCancel.id);
            await fetchPurchases(); // Refresh the purchases list
            setError(null);
            document.getElementById('cancel_modal').close();
            setSubscriptionToCancel(null);
        } catch (err) {
            setError('Failed to cancel subscription');
            console.error('Error cancelling subscription:', err);
        } finally {
            setLoading(false);
        }
    };

    if (!profile) return <div className="flex justify-center items-center h-screen">Loading...</div>;

    return (
        <>
            <Helmet>
                <title>Profile | {process.env.REACT_APP_SITE_NAME}</title>
                <meta name="description" content="Manage your Django ShipFast profile, subscription settings, and account preferences. View your purchase history and update your personal information." />
                <meta name="keywords" content="Django Shipfast profile, account settings, user dashboard, subscription management" />
                <link rel="canonical" href={`https://${process.env.REACT_APP_SITE_DOMAIN}/profile`} />

                {/* Open Graph tags */}
                <meta property="og:type" content="website" />
                <meta property="og:title" content={`Profile | ${process.env.REACT_APP_SITE_NAME}`} />
                <meta property="og:description" content="Manage your Django Shipfast profile, subscription settings, and account preferences. View your purchase history and update your personal information." />
                <meta property="og:url" content={`https://${process.env.REACT_APP_SITE_DOMAIN}/profile`} />
                <meta property="og:image" content={`https://${process.env.REACT_APP_SITE_DOMAIN}/link-preview.png`} />

                {/* Twitter Card tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={`Profile | ${process.env.REACT_APP_SITE_NAME}`} />
                <meta name="twitter:description" content="Manage your Django Shipfast profile, subscription settings, and account preferences. View your purchase history and update your personal information." />
                <meta name="twitter:url" content={`https://${process.env.REACT_APP_SITE_DOMAIN}/profile`} />
                <meta name="twitter:image" content={`https://${process.env.REACT_APP_SITE_DOMAIN}/link-preview.png`} />
            </Helmet>
            <div className="container mx-auto mt-8 p-4 max-w-3xl">
                <div className="card bg-base-100 shadow-xl">
                    <div className="flex flex-col md:flex-row">
                        <figure className="px-10 pt-10 pb-6 md:w-1/3">
                            <div className="avatar">
                                <div className="w-32 h-32 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2 overflow-hidden">
                                    <div className="bg-neutral-focus text-neutral-content rounded-full w-full h-full flex items-center justify-center">
                                        <span className="text-4xl">{profile.username ? profile.username[0].toUpperCase() : 'U'}</span>
                                    </div>
                                </div>
                            </div>
                        </figure>
                        <div className="card-body md:w-2/3">
                            <h2 className="card-title text-2xl font-bold mb-4">Your Account Snapshot</h2>
                            {error && <div className="alert alert-error">{error}</div>}
                            {isEditing ? (
                                <form onSubmit={handleSubmit}>
                                    <div className="form-control">
                                        <label className="label">
                                            <span className="label-text">Username:</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="username"
                                            value={formData.username || ''}
                                            onChange={handleInputChange}
                                            className={`input input-bordered w-full max-w-xs ${
                                                usernameError ? 'border-red-500 focus:border-red-500' : ''
                                            }`}
                                            pattern="^[a-zA-Z0-9_]{1,20}$"
                                            title="Username can only contain letters, numbers, and underscores, and must be 1-20 characters long"
                                            maxLength={20}
                                        />
                                        <label className="label">
                                            <span className="label-text-alt text-warning">
                                                Note: Choose your username wisely!
                                            </span>
                                        </label>
                                        {usernameError && (
                                            <p className="text-red-500 text-sm mt-1">{usernameError}</p>
                                        )}
                                    </div>
                                    <div className="card-actions justify-end mt-4">
                                        <button type="submit" className="btn btn-primary">Save</button>
                                        <button type="button" onClick={() => setIsEditing(false)} className="btn btn-ghost">Cancel</button>
                                    </div>
                                </form>
                            ) : (
                                <div>
                                    <p><strong>Email:</strong> {profile.email}</p>
                                    <p><strong>Username:</strong> {profile.username || 'Not set'}</p>
                                    {profile.membership_name && (
                                        <div className="mt-4">
                                            <h3 className="text-lg font-semibold">Membership</h3>
                                            <p><strong>Plan:</strong> {profile.membership_name}</p>
                                            <p><strong>Description:</strong> {profile.membership_description}</p>
                                        </div>
                                    )}
                                    <div className="card-actions justify-end mt-4">
                                        <button onClick={() => setIsEditing(true)} className="btn btn-primary">Edit Profile</button>
                                        <Link to="/password-change" className="btn btn-error">Change Password</Link>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mx-auto p-4 max-w-4xl">
                {/* User Info Section */}
                <div className="card bg-base-200 shadow-xl mb-8">
                    <div className="card-body">
                        <h2 className="card-title">User Information</h2>
                        <p>Email: {user?.email || 'N/A'}</p>
                        <p>Member since: {user?.date_joined ? formatDate(user.date_joined) : 'N/A'}</p>
                    </div>
                </div>

                {/* Active Subscriptions Section */}
                <div className="card bg-base-200 shadow-xl mb-8">
                    <div className="card-body">
                        <h2 className="card-title mb-4">Active Subscriptions</h2>
                        {purchases.subscriptions.length > 0 ? (
                            <div className="overflow-x-auto">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Product</th>
                                            <th>Price</th>
                                            <th>Status</th>
                                            <th>Expires</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {purchases.subscriptions.map((sub) => (
                                            <tr key={sub.id}>
                                                <td>{sub.product.name}</td>
                                                <td>{sub.plan.amount} {sub.plan.currency} / {sub.plan.interval_count} {sub.plan.interval}</td>
                                                <td>
                                                    <div className="flex items-center gap-2">
                                                        <span className={`badge ${
                                                            sub.status === 'active' ? 'badge-success' :
                                                            sub.status === 'canceling' ? 'badge-warning' : 'badge-error'
                                                        }`}>
                                                            {sub.status}
                                                        </span>
                                                        {sub.cancel_at_period_end && (
                                                            <span className="badge badge-warning">Cancels at period end</span>
                                                        )}
                                                    </div>
                                                </td>
                                                <td>{formatDate(sub.current_period_end)}</td>
                                                <td>
                                                    {sub.status === 'active' && (
                                                        <button
                                                            className="btn btn-error btn-sm"
                                                            onClick={() => openCancelModal(sub)}
                                                            disabled={sub.cancel_at_period_end}
                                                        >
                                                            Cancel
                                                        </button>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <p className="text-gray-500">No active subscriptions</p>
                        )}
                    </div>
                </div>

                {/* One-Time Purchases Section */}
                <div className="card bg-base-200 shadow-xl">
                    <div className="card-body">
                        <h2 className="card-title mb-4">Purchase History</h2>
                        {purchases.one_time_purchases.length > 0 ? (
                            <div className="overflow-x-auto">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Product</th>
                                            <th>Price</th>
                                            <th>Purchase Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {purchases.one_time_purchases.map((purchase) => (
                                            <tr key={purchase.id}>
                                                <td>{purchase.product?.name || 'N/A'}</td>
                                                <td>{purchase.amount / 100} {purchase.currency}</td>
                                                <td>{formatDate(purchase.created)}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <p className="text-gray-500">No purchase history</p>
                        )}
                    </div>
                </div>

                {/* Error Display */}
                {error && (
                    <div className="alert alert-error mt-4">
                        <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <span>{error}</span>
                    </div>
                )}
            </div>
            <dialog id="cancel_modal" className="modal modal-bottom sm:modal-middle">
                <div className="modal-box">
                    <h3 className="font-bold text-lg">Cancel Subscription</h3>
                    <p className="py-4">
                        Are you sure you want to cancel your subscription to {subscriptionToCancel?.product_name}?
                        You'll continue to have access until the end of your current billing period
                        ({formatDate(subscriptionToCancel?.current_period_end)}).
                    </p>
                    <div className="modal-action">
                        <form method="dialog">
                            <button
                                className="btn btn-error mr-2"
                                onClick={handleCancelSubscription}
                                disabled={loading}
                            >
                                {loading ? (
                                    <span className="loading loading-spinner loading-sm"></span>
                                ) : (
                                    'Yes, Cancel'
                                )}
                            </button>
                            <button className="btn">No, Keep Subscription</button>
                        </form>
                    </div>
                </div>
            </dialog>
        </>
    );
};

export default Profile;
