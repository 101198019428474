import axios from 'axios';
import { handleLogoutOnTokenExpiry } from './authService';

// Create an instance of axios
export const api = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL + '/api/' : '/api/',
    withCredentials: true,
});

// Log requests
api.interceptors.request.use(request => {
    console.log('Starting Request', request.method, request.url);
    return request;
});

// Log responses
api.interceptors.response.use(response => {
    console.log('Response:', response.status, response.config.url);
    return response;
});

export const refreshToken = async () => {
    try {
        const refreshToken = localStorage.getItem('refresh_token');
        if (!refreshToken) {
            throw new Error('No refresh token available');
        }
        const response = await api.post('auth/token/refresh/', { refresh: refreshToken });
        localStorage.setItem('access_token', response.data.access);
        await fetchCSRFToken();
        return response.data.access;
    } catch (error) {
        console.error('Error refreshing token:', error);
        throw error;
    }
};

export const fetchCSRFToken = async () => {
    try {
        await api.get('auth/csrf/');
        console.log('CSRF token fetched');
    } catch (error) {
        console.error('Error fetching CSRF token:', error);
    }
};

// Add a request interceptor
api.interceptors.request.use(async (config) => {
    // Get the JWT token from localStorage
    let token = localStorage.getItem('access_token');

    // // Add check for token expiration
    // if (token && isTokenExpired(token)) {
    //     try {
    //         // Try to refresh the token before proceeding with the request
    //         const newToken = await refreshToken();
    //         token = newToken;
    //     } catch (error) {
    //         // If refresh fails, handle logout
    //         handleLogoutOnTokenExpiry();
    //         return Promise.reject(error);
    //     }
    // }

    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }

    if (config.method !== 'get') {
        // Get the CSRF token from the cookie
        // console.log('All cookies:', document.cookie);
        const csrfToken = document.cookie.split('; ')
            .find(row => row.startsWith('csrftoken='))
            ?.split('=')[1];

        if (csrfToken) {
            config.headers['X-CSRFToken'] = csrfToken;
            // console.log('CSRF token found and set:', csrfToken);
        } else {
            console.warn('CSRF token not found in cookies');
            console.log('All cookies:', document.cookie);
        }
    }

    return config;
}, (error) => {
    return Promise.reject(error);
});

// // Add this function to check token expiration
// const isTokenExpired = (token) => {
//     if (!token) return true;
//     try {
//         const payload = JSON.parse(atob(token.split('.')[1]));
//         return payload.exp * 1000 < Date.now();
//     } catch (e) {
//         return true;
//     }
// };

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });
    failedQueue = [];
};

// Update your axios interceptor to handle token refresh
api.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        // If the error is not 401 or the request was for refreshing token, reject immediately
        if (error.response?.status !== 401 || originalRequest.url === 'auth/token/refresh/') {
            return Promise.reject(error);
        }

        // If we don't have a refresh token, logout
        if (!localStorage.getItem('refresh_token')) {
            handleLogoutOnTokenExpiry();
            return Promise.reject(error);
        }

        // If token refresh is already in progress, queue this request
        if (isRefreshing) {
            return new Promise((resolve, reject) => {
                failedQueue.push({ resolve, reject });
            })
                .then((token) => {
                    originalRequest.headers['Authorization'] = 'Bearer ' + token;
                    return api(originalRequest);
                })
                .catch((err) => Promise.reject(err));
        }

        isRefreshing = true;

        try {
            const refreshToken = localStorage.getItem('refresh_token');
            const response = await api.post('auth/token/refresh/', {
                refresh: refreshToken
            });

            const { access } = response.data;
            localStorage.setItem('access_token', access);

            originalRequest.headers['Authorization'] = 'Bearer ' + access;
            processQueue(null, access);

            return api(originalRequest);
        } catch (refreshError) {
            processQueue(refreshError, null);
            handleLogoutOnTokenExpiry();
            return Promise.reject(refreshError);
        } finally {
            isRefreshing = false;
        }
    }
);

export default api;
