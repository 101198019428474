import React from 'react';
import { Helmet } from 'react-helmet-async';

const PrivacyPolicy = () => {
    return (
        <>
            <Helmet>
                <title>Privacy Policy | {process.env.REACT_APP_SITE_NAME}</title>
                <meta name="description" content="Learn how Django ShipFast protects your data. Our privacy policy outlines our commitment to security, data protection, and GDPR compliance." />
                <meta name="keywords" content="Django Shipfast privacy, data protection, GDPR compliance, security policy, cookie policy" />
                <link rel="canonical" href={`https://${process.env.REACT_APP_SITE_DOMAIN}/privacy-policy`} />
            </Helmet>
            <div className="container mx-auto px-4 py-8 max-w-4xl">
                <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">Cookie Policy</h2>
                    <p className="mb-4">We use cookies and similar technologies to enhance your browsing experience and for essential site functionality. By clicking "Accept" or continuing to use our site, you agree to this use of cookies and our Privacy Policy.</p>

                    <h3 className="text-xl font-semibold mb-2">Types of Cookies We Use:</h3>
                    <ul className="list-disc pl-6 mb-4">
                        <li className="mb-2">
                            <strong>Essential Cookies (Strictly Necessary):</strong> Required for basic site functionality and maintaining your login session. These cookies are exempt from requiring consent under GDPR.
                        </li>
                        <li className="mb-2">
                            <strong>Preference Cookies (Functionality):</strong> Remember your settings and PWA installation status. These can be disabled but may affect your experience.
                        </li>
                        <li className="mb-2">
                            <strong>Duration:</strong> Our cookies are session-based or persist for up to 12 months.
                        </li>
                    </ul>

                    <h3 className="text-xl font-semibold mb-2">Your Cookie Choices:</h3>
                    <ul className="list-disc pl-6 mb-4">
                        <li className="mb-2">You can modify your cookie preferences at any time through our Cookie Settings.</li>
                        <li className="mb-2">Most web browsers allow control of cookies through browser settings.</li>
                        <li className="mb-2">You may opt out of non-essential cookies while maintaining access to basic site functionality.</li>
                    </ul>

                    <p className="text-sm text-base-content/70">
                        For more information about cookies, visit{' '}
                        <a
                            href="https://www.allaboutcookies.org/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-primary hover:underline"
                        >
                            AllAboutCookies.org
                        </a>
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">Progressive Web App (PWA)</h2>
                    <p className="mb-4">Our website can be installed as a Progressive Web App. When you choose to install:</p>
                    <ul className="list-disc pl-6 mb-4">
                        <li className="mb-2">We store app preferences locally on your device</li>
                        <li className="mb-2">Installation status is tracked for better user experience</li>
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">Information We Collect</h2>
                    <p className="mb-4">We collect information that you provide directly to us, including:</p>
                    <ul className="list-disc pl-6">
                        <li className="mb-2">Account information (email and username)</li>
                        <li className="mb-2">Authentication tokens for secure access</li>
                        <li className="mb-2">Basic usage data for site functionality</li>
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">Payment Processing</h2>
                    <p className="mb-4">For payment processing:</p>
                    <ul className="list-disc pl-6">
                        <li className="mb-2">All payments are processed securely through Stripe</li>
                        <li className="mb-2">We never collect or store your payment information</li>
                        <li className="mb-2">We only store basic subscription and transaction records</li>
                        <li className="mb-2">For payment-related privacy details, please refer to <a href="https://stripe.com/privacy" target="_blank" rel="noopener noreferrer" className="text-primary hover:underline">Stripe's Privacy Policy</a></li>
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">How We Use Your Information</h2>
                    <ul className="list-disc pl-6">
                        <li className="mb-2">To provide and maintain our services</li>
                        <li className="mb-2">To process your transactions</li>
                        <li className="mb-2">To send essential account-related emails</li>
                        <li className="mb-2">To improve our website functionality</li>
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">Third-Party Services</h2>
                    <p className="mb-4">We use the following third-party services:</p>
                    <ul className="list-disc pl-6">
                        <li className="mb-2">Stripe for payment processing</li>
                    </ul>
                </section>

                <section>
                    <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
                    <p>If you have any questions about our Privacy Policy, please contact us at:</p>
                    <p className="mt-2">Email: {process.env.REACT_APP_CONTACT_EMAIL || process.env.REACT_APP_SUPPORT_EMAIL || `info@${process.env.REACT_APP_SITE_DOMAIN}`}</p>
                    <p className="mt-4 text-sm text-base-content/70">Last updated: {new Date().toLocaleDateString()}</p>
                </section>
            </div>
        </>
    );
};

export default PrivacyPolicy;
