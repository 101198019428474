import React from 'react';
import { Link } from 'react-router-dom';
import { FaStripe, FaAws } from 'react-icons/fa';
import { SiPostgresql, SiRedis, SiMailgun, SiResend, SiRailway, SiRender, SiDjango, SiReact, SiTailwindcss, SiPwa } from 'react-icons/si';
import { TbFileDescription } from 'react-icons/tb';
import { BiCookie } from 'react-icons/bi';

const LandingPage = () => {
    return (
        <div className="bg-base-200 min-h-screen">
            <main className="container mx-auto px-4 py-16">
                <div className="hero mb-24">
                    <div className="hero-content text-center">
                        <div className="max-w-4xl">
                            <h2 className="text-6xl font-black mb-6 font-display">
                                Supercharge Your <span className="text-primary">Django + React</span> Development
                            </h2>
                            <p className="text-2xl leading-relaxed mb-12 text-left">
                                Tired of spending weeks setting up the same features for every project?
                                A production-ready Django-React foundation with authentication, payments, and infrastructure ready to go —
                                so you can focus on what makes your product unique from day one!!
                            </p>

                            <Link
                                to="/pricing"
                                className="btn btn-primary btn-lg text-lg"
                            >
                                Start Building Now
                            </Link>
                        </div>
                    </div>
                </div>

                <section className="mb-24">
                    <div className="flex items-center mb-8">
                        <div className="h-px flex-1 bg-primary/30"></div>
                        <span className="px-4 text-3xl font-display font-bold text-secondary">WHAT YOU CAN BUILD</span>
                        <div className="h-px flex-1 bg-primary/30"></div>
                    </div>

                    <div className="grid md:grid-cols-3 gap-6 max-w-6xl mx-auto">
                        <div className="card bg-base-100 shadow-xl hover:shadow-2xl transition-all duration-300 hover:-translate-y-1">
                            <div className="card-body">
                                <h3 className="card-title text-xl mb-2">SaaS Dashboard</h3>
                                <p className="text-base-content/70">
                                    Build subscription-based services like Buffer or Later.com with
                                    tiered pricing, team accounts, and usage tracking.
                                </p>
                                <div className="mt-4">
                                    <div className="badge badge-outline mr-2">Subscriptions</div>
                                    <div className="badge badge-outline mr-2">Teams</div>
                                    <div className="badge badge-outline">Analytics</div>
                                </div>
                            </div>
                        </div>

                        <div className="card bg-base-100 shadow-xl hover:shadow-2xl transition-all duration-300 hover:-translate-y-1 animate-[fadeIn_0.5s_ease-in]">
                            <div className="card-body">
                                <h3 className="card-title text-xl mb-2">Digital Downloads</h3>
                                <p className="text-base-content/70">
                                    Create a LemonSqueezy-style platform to sell digital products with
                                    secure delivery and license key management.
                                </p>
                                <div className="mt-4">
                                    <div className="badge badge-outline mr-2">File Storage</div>
                                    <div className="badge badge-outline mr-2">Payments</div>
                                    <div className="badge badge-outline">Licensing</div>
                                </div>
                            </div>
                        </div>

                        <div className="card bg-base-100 shadow-xl hover:shadow-2xl transition-all duration-300 hover:-translate-y-1 animate-[fadeIn_0.5s_ease-in]">
                            <div className="card-body">
                                <h3 className="card-title text-xl mb-2">Membership Site</h3>
                                <p className="text-base-content/70">
                                    Launch a premium content platform like Substack with paid newsletters,
                                    member-only content, and subscription management.
                                </p>
                                <div className="mt-4">
                                    <div className="badge badge-outline mr-2">Auth</div>
                                    <div className="badge badge-outline mr-2">Payments</div>
                                    <div className="badge badge-outline">Email</div>
                                </div>
                            </div>
                        </div>

                        <div className="card bg-base-100 shadow-xl hover:shadow-2xl transition-all duration-300 hover:-translate-y-1 animate-[fadeIn_0.5s_ease-in]">
                            <div className="card-body">
                                <h3 className="card-title text-xl mb-2">API Service</h3>
                                <p className="text-base-content/70">
                                    Develop an API-as-a-service like Clearbit with usage quotas,
                                    API key management, and automated billing.
                                </p>
                                <div className="mt-4">
                                    <div className="badge badge-outline mr-2">API Keys</div>
                                    <div className="badge badge-outline mr-2">Rate Limiting</div>
                                    <div className="badge badge-outline">Usage Tracking</div>
                                </div>
                            </div>
                        </div>

                        <div className="card bg-base-100 shadow-xl hover:shadow-2xl transition-all duration-300 hover:-translate-y-1 animate-[fadeIn_0.5s_ease-in]">
                            <div className="card-body">
                                <h3 className="card-title text-xl mb-2">Booking Platform</h3>
                                <p className="text-base-content/70">
                                    Create a Cal.com-inspired scheduling system with paid appointments,
                                    automated emails, and calendar integration.
                                </p>
                                <div className="mt-4">
                                    <div className="badge badge-outline mr-2">Payments</div>
                                    <div className="badge badge-outline mr-2">Background Tasks</div>
                                    <div className="badge badge-outline">Email</div>
                                </div>
                            </div>
                        </div>

                        <div className="card bg-base-100 shadow-xl hover:shadow-2xl transition-all duration-300 hover:-translate-y-1 animate-[fadeIn_0.5s_ease-in]">
                            <div className="card-body">
                                <h3 className="card-title text-xl mb-2">Learning Platform</h3>
                                <p className="text-base-content/70">
                                    Build a Podia-like platform for selling courses with secure video delivery,
                                    progress tracking, and student management.
                                </p>
                                <div className="mt-4">
                                    <div className="badge badge-outline mr-2">File Storage</div>
                                    <div className="badge badge-outline mr-2">Auth</div>
                                    <div className="badge badge-outline">Payments</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="text-center mt-8 text-lg text-base-content/70 italic">
                        And countless other possibilities limited only by your imagination...
                    </div>
                </section>

                <section className="mb-24">
                    <div className="flex items-center mb-8">
                        <div className="h-px flex-1 bg-primary/30"></div>
                        <span className="px-4 text-3xl font-display font-bold text-secondary">WHAT'S IN THE STACK</span>
                        <div className="h-px flex-1 bg-primary/30"></div>
                    </div>

                    <div className="grid grid-cols-1 gap-8 max-w-4xl mx-auto">
                        <div className="bg-base-100 p-8 rounded-lg">
                            <h3 className="text-2xl font-display mb-6">Tech Stack & Tools</h3>

                            <div className="hidden md:block overflow-x-auto">
                                <table className="table table-zebra">
                                    <thead>
                                        <tr className="text-base-content">
                                            <th>FEATURE</th>
                                            <th>CONFIGURABLE</th>
                                            <th>DEFAULT / BUILT-IN</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {[
                                            {
                                                feature: <span className="font-semibold">Database</span>,
                                                configurable: (
                                                    <a href="https://www.postgresql.org/" target="_blank" rel="noopener noreferrer"
                                                       className="inline-flex items-center gap-2 font-semibold text-primary hover:text-primary-focus">
                                                        <SiPostgresql className="w-5 h-5 shrink-0 text-base-content" />
                                                        <span>PostgreSQL</span>
                                                    </a>
                                                ),
                                                default: (
                                                    <div className="flex items-center gap-2">
                                                        <span className="badge badge-secondary">fallback</span>
                                                        <span className="opacity-75 font-semibold">SQLite</span>
                                                    </div>
                                                )
                                            },
                                            {
                                                feature: <span className="font-semibold">Email</span>,
                                                configurable: (
                                                    <div className="inline-flex items-center gap-2 font-semibold text-base-content">
                                                        <span className="inline-flex items-center gap-1">
                                                            <SiMailgun className="w-5 h-5 shrink-0" />
                                                            <a href="https://www.mailgun.com/" className="text-primary hover:text-primary-focus">Mailgun</a>
                                                            {" or "}
                                                            <SiResend className="w-5 h-5 ml-1" />
                                                            <a href="https://resend.com/" className="text-primary hover:text-primary-focus">Resend</a>
                                                        </span>
                                                    </div>
                                                ),
                                                default: (
                                                    <div className="flex items-center gap-2">
                                                        <span className="badge badge-secondary">fallback</span>
                                                        <span className="opacity-75 font-semibold">SMTP</span>
                                                    </div>
                                                )
                                            },
                                            {
                                                feature: <span className="font-semibold">Payment</span>,
                                                configurable: (
                                                    <div className="inline-flex items-center gap-2 font-semibold text-base-content">
                                                        <FaStripe className="w-5 h-5 shrink-0" />
                                                        <a href="https://stripe.com/" target="_blank" rel="noopener noreferrer"
                                                           className="text-primary hover:text-primary-focus">Stripe</a> (checkout, webhook)
                                                    </div>
                                                ),
                                                default: (
                                                    <div className="flex items-center gap-2">
                                                        <span className="badge badge-secondary">fallback</span>
                                                        <span className="opacity-75 font-semibold">No payment support</span>
                                                    </div>
                                                )
                                            },
                                            {
                                                feature: <span className="font-semibold">Caching</span>,
                                                configurable: (
                                                    <div className="inline-flex items-center gap-2 font-semibold text-base-content">
                                                        <SiRedis className="w-5 h-5 shrink-0" />
                                                        <a href="https://redis.io/" target="_blank" rel="noopener noreferrer"
                                                           className="text-primary hover:text-primary-focus">Redis</a>
                                                    </div>
                                                ),
                                                default: (
                                                    <div className="flex items-center gap-2">
                                                        <span className="badge badge-secondary">fallback</span>
                                                        <span className="opacity-75 font-semibold">Memory cache</span>
                                                    </div>
                                                )
                                            },
                                            {
                                                feature: <span className="font-semibold">Background Tasks</span>,
                                                configurable: (
                                                    <div className="inline-flex items-center gap-2 font-semibold text-base-content">
                                                        <SiDjango className="w-5 h-5 shrink-0" />
                                                        <a href="https://docs.celeryq.dev/" target="_blank" rel="noopener noreferrer"
                                                           className="text-primary hover:text-primary-focus">Celery</a>
                                                    </div>
                                                ),
                                                default: (
                                                    <div className="flex items-center gap-2">
                                                        <span className="badge badge-secondary">fallback</span>
                                                        <span className="opacity-75 font-semibold">No background tasks</span>
                                                    </div>
                                                )
                                            },
                                            {
                                                feature: <span className="font-semibold">Media Storage</span>,
                                                configurable: (
                                                    <div className="inline-flex items-center gap-2 font-semibold text-base-content">
                                                        <FaAws className="w-5 h-5 shrink-0" />
                                                        <a href="https://aws.amazon.com/s3/" target="_blank" rel="noopener noreferrer"
                                                           className="text-primary hover:text-primary-focus">AWS S3</a>
                                                    </div>
                                                ),
                                                default: (
                                                    <div className="flex items-center gap-2">
                                                        <span className="badge badge-secondary">fallback</span>
                                                        <span className="opacity-75 font-semibold">Local storage</span>
                                                    </div>
                                                )
                                            },
                                            {
                                                feature: <span className="font-semibold">Superuser</span>,
                                                configurable: (
                                                    <div className="flex items-center gap-2">
                                                        <SiDjango className="w-5 h-5 shrink-0" />
                                                        <span className="opacity-75 font-semibold">Create Superuser during deployment</span>
                                                    </div>
                                                ),
                                                default: (
                                                    <div className="flex items-center gap-2">
                                                        <span className="badge badge-secondary">fallback</span>
                                                        <span className="opacity-75 font-semibold">No superuser created</span>
                                                    </div>
                                                )
                                            },
                                            {
                                                feature: <span className="font-semibold">Authentication</span>,
                                                configurable: (
                                                    <div className="flex items-center gap-2">
                                                        <span>—</span>
                                                    </div>
                                                ),
                                                default: (
                                                    <div className="flex items-center gap-2">
                                                        <span className="badge badge-secondary whitespace-nowrap">built-in</span>
                                                        <span className="opacity-75 font-semibold">Login (email or username), logout, signup, password reset.</span>
                                                    </div>
                                                )
                                            },
                                            {
                                                feature: <span className="font-semibold">Static Files</span>,
                                                configurable: (
                                                    <div className="flex items-center gap-2">
                                                        <span>—</span>
                                                    </div>
                                                ),
                                                default: (
                                                    <div className="flex items-center gap-2">
                                                        <span className="badge badge-secondary whitespace-nowrap">built-in</span>
                                                        <span className="opacity-75 font-semibold">Served by <a href="https://whitenoise.readthedocs.io/" target="_blank" rel="noopener noreferrer" className="text-primary hover:text-primary-focus">WhiteNoise</a> in production, by Django in development</span>
                                                    </div>
                                                )
                                            },
                                            {
                                                feature: <span className="font-semibold">Frontend API Client</span>,
                                                configurable: (
                                                    <div className="flex items-center gap-2">
                                                        <span>—</span>
                                                    </div>
                                                ),
                                                default: (
                                                    <div className="flex items-center gap-2">
                                                        <span className="badge badge-secondary whitespace-nowrap">built-in</span>
                                                        <span className="opacity-75 font-semibold">Auth, Profile, Products / Pricing, Payment, etc.</span>
                                                    </div>
                                                )
                                            },
                                            {
                                                feature: <span className="font-semibold">SEO</span>,
                                                configurable: (
                                                    <div className="flex items-center gap-2">
                                                        <span>—</span>
                                                    </div>
                                                ),
                                                default: (
                                                    <div className="flex items-center gap-2">
                                                        <span className="badge badge-secondary whitespace-nowrap">built-in</span>
                                                        <SiReact className="w-5 h-5 shrink-0 text-base-content" />
                                                        <span className="opacity-75 font-semibold">React Helmet, meta tags, sitemaps, link preview tags.</span>
                                                    </div>
                                                )
                                            },
                                            {
                                                feature: <span className="font-semibold">PWA Support</span>,
                                                configurable: (
                                                    <div className="flex items-center gap-2">
                                                        <span>—</span>
                                                    </div>
                                                ),
                                                default: (
                                                    <div className="flex items-center gap-2">
                                                        <span className="badge badge-secondary whitespace-nowrap">built-in</span>
                                                        <SiPwa className="w-5 h-5 shrink-0 text-base-content" />
                                                        <span className="opacity-75 font-semibold">Installation prompt, service worker</span>
                                                    </div>
                                                )
                                            },
                                            {
                                                feature: <span className="font-semibold">Admin Panel</span>,
                                                configurable: (
                                                    <div className="flex items-center gap-2">
                                                        <span>—</span>
                                                    </div>
                                                ),
                                                default: (
                                                    <div className="flex items-center gap-2">
                                                        <span className="badge badge-secondary whitespace-nowrap">built-in</span>
                                                        <SiDjango className="w-5 h-5 shrink-0 text-base-content" />
                                                        <span className="opacity-75 font-semibold">Django Admin</span>
                                                    </div>
                                                )
                                            },
                                            {
                                                feature: <span className="font-semibold">Cookie Consent</span>,
                                                configurable: (
                                                    <div className="flex items-center gap-2">
                                                        <span>—</span>
                                                    </div>
                                                ),
                                                default: (
                                                    <div className="flex items-center gap-2">
                                                        <span className="badge badge-secondary whitespace-nowrap">built-in</span>
                                                        <BiCookie className="w-5 h-5 shrink-0 text-base-content" />
                                                        <span className="opacity-75 font-semibold">GDPR-compliant Cookie Management</span>
                                                    </div>
                                                )
                                            }
                                        ].map((row, index) => (
                                            <tr key={index}>
                                                <td>{row.feature}</td>
                                                <td>{row.configurable}</td>
                                                <td>{row.default}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            <div className="md:hidden space-y-6">
                                {[
                                    {
                                        feature: <span className="font-semibold">Database</span>,
                                        configurable: (
                                            <div className="inline-flex items-center gap-2 font-semibold text-base-content">
                                                <span className="badge badge-outline mb-1">Configurable</span>
                                                <a href="https://www.postgresql.org/" target="_blank" rel="noopener noreferrer"
                                                   className="inline-flex items-center gap-2 font-semibold text-primary hover:text-primary-focus">
                                                    <SiPostgresql className="w-5 h-5 shrink-0 text-base-content" />
                                                    <span>PostgreSQL</span>
                                                </a>
                                            </div>
                                        ),
                                        default: (
                                            <div className="flex items-center gap-2">
                                                <span className="badge badge-secondary">fallback</span>
                                                <span className="opacity-75 font-semibold">SQLite</span>
                                            </div>
                                        )
                                    },
                                    {
                                        feature: <span className="font-semibold">Email</span>,
                                        configurable: (
                                            <div className="inline-flex items-center gap-2 font-semibold text-base-content">
                                                <span className="badge badge-outline mb-1">Configurable</span>
                                                <span className="inline-flex items-center gap-1">
                                                    <SiMailgun className="w-5 h-5 shrink-0" />
                                                    <a href="https://www.mailgun.com/" className="text-primary hover:text-primary-focus">Mailgun</a>
                                                    {" or "}
                                                    <SiResend className="w-5 h-5 ml-1" />
                                                    <a href="https://resend.com/" className="text-primary hover:text-primary-focus">Resend</a>
                                                </span>
                                            </div>
                                        ),
                                        default: (
                                            <div className="flex items-center gap-2">
                                                <span className="badge badge-secondary">fallback</span>
                                                <span className="opacity-75 font-semibold">SMTP</span>
                                            </div>
                                        )
                                    },
                                    {
                                        feature: <span className="font-semibold">Payment</span>,
                                        configurable: (
                                            <div className="inline-flex items-center gap-2 font-semibold text-base-content">
                                                <span className="badge badge-outline mb-1">Configurable</span>
                                                <FaStripe className="w-5 h-5 shrink-0" />
                                                <a href="https://stripe.com/" target="_blank" rel="noopener noreferrer"
                                                   className="text-primary hover:text-primary-focus">Stripe</a> (Checkout, Webhook)
                                            </div>
                                        ),
                                        default: (
                                            <div className="flex items-center gap-2">
                                                <span className="badge badge-secondary">fallback</span>
                                                <span className="opacity-75 font-semibold">No payment support</span>
                                            </div>
                                        )
                                    },
                                    {
                                        feature: <span className="font-semibold">Caching</span>,
                                        configurable: (
                                            <div className="inline-flex items-center gap-2 font-semibold text-base-content">
                                                <span className="badge badge-outline mb-1">Configurable</span>
                                                <SiRedis className="w-5 h-5 shrink-0" />
                                                <a href="https://redis.io/" target="_blank" rel="noopener noreferrer"
                                                   className="text-primary hover:text-primary-focus">Redis</a>
                                            </div>
                                        ),
                                        default: (
                                            <div className="flex items-center gap-2">
                                                <span className="badge badge-secondary">fallback</span>
                                                <span className="opacity-75 font-semibold">Memory cache</span>
                                            </div>
                                        )
                                    },
                                    {
                                        feature: <span className="font-semibold">Background Tasks</span>,
                                        configurable: (
                                            <div className="inline-flex items-center gap-2 font-semibold text-base-content">
                                                <span className="badge badge-outline mb-1">Configurable</span>
                                                <SiDjango className="w-5 h-5 shrink-0" />
                                                <a href="https://docs.celeryq.dev/" target="_blank" rel="noopener noreferrer"
                                                   className="text-primary hover:text-primary-focus">Celery</a>
                                            </div>
                                        ),
                                        default: (
                                            <div className="flex items-center gap-2">
                                                <span className="badge badge-secondary">fallback</span>
                                                <span className="opacity-75 font-semibold">No background tasks</span>
                                            </div>
                                        )
                                    },
                                    {
                                        feature: <span className="font-semibold">Media Storage</span>,
                                        configurable: (
                                            <div className="inline-flex items-center gap-2 font-semibold text-base-content">
                                                <span className="badge badge-outline mb-1">Configurable</span>
                                                <FaAws className="w-5 h-5 shrink-0" />
                                                <a href="https://aws.amazon.com/s3/" target="_blank" rel="noopener noreferrer"
                                                   className="text-primary hover:text-primary-focus">AWS S3</a>
                                            </div>
                                        ),
                                        default: (
                                            <div className="flex items-center gap-2">
                                                <span className="badge badge-secondary">fallback</span>
                                                <span className="opacity-75 font-semibold">Local storage</span>
                                            </div>
                                        )
                                    },
                                    {
                                        feature: <span className="font-semibold">Superuser</span>,
                                        configurable: (
                                            <div className="flex items-center gap-2">
                                                <span className="badge badge-outline mb-1">Configurable</span>
                                                <SiDjango className="w-5 h-5 shrink-0" />
                                                <span className="opacity-75 font-semibold">Create Superuser during deployment</span>
                                            </div>
                                        ),
                                        default: (
                                            <div className="flex items-center gap-2">
                                                <span className="badge badge-secondary">fallback</span>
                                                <span className="opacity-75 font-semibold">No superuser created</span>
                                            </div>
                                        )
                                    },
                                    {
                                        feature: <span className="font-semibold">Authentication</span>,
                                        configurable: null,
                                        default: (
                                            <div className="flex items-center gap-2">
                                                <span className="badge badge-secondary whitespace-nowrap">built-in</span>
                                                <span className="opacity-75 font-semibold">Login (email or username), logout, signup, password reset</span>
                                            </div>
                                        )
                                    },
                                    {
                                        feature: <span className="font-semibold">Static Files</span>,
                                        configurable: null,
                                        default: (
                                            <div className="flex items-center gap-2">
                                                <span className="badge badge-secondary whitespace-nowrap">built-in</span>
                                                <span className="opacity-75 font-semibold">Served by <a href="https://whitenoise.readthedocs.io/" target="_blank" rel="noopener noreferrer" className="text-primary hover:text-primary-focus">WhiteNoise</a> in production, by Django in development</span>
                                            </div>
                                        )
                                    },
                                    {
                                        feature: <span className="font-semibold">Frontend API Client</span>,
                                        configurable: null,
                                        default: (
                                            <div className="flex items-center gap-2">
                                                <span className="badge badge-secondary whitespace-nowrap">built-in</span>
                                                <span className="opacity-75 font-semibold">Auth, Profile, Products / Pricing, Payment, etc.</span>
                                            </div>
                                        )
                                    },
                                    {
                                        feature: <span className="font-semibold">SEO</span>,
                                        configurable: null,
                                        default: (
                                            <div className="flex items-center gap-2">
                                                <span className="badge badge-secondary whitespace-nowrap">built-in</span>
                                                <SiReact className="w-5 h-5 shrink-0 text-base-content" />
                                                <span className="opacity-75 font-semibold">React Helmet, meta tags, sitemaps, link preview tags</span>
                                            </div>
                                        )
                                    },
                                    {
                                        feature: <span className="font-semibold">PWA Support</span>,
                                        configurable: null,
                                        default: (
                                            <div className="flex items-center gap-2">
                                                <span className="badge badge-secondary whitespace-nowrap">built-in</span>
                                                <SiPwa className="w-5 h-5 shrink-0 text-base-content" />
                                                <span className="opacity-75 font-semibold">Installation prompt, service worker</span>
                                            </div>
                                        )
                                    },
                                    {
                                        feature: <span className="font-semibold">Admin Panel</span>,
                                        configurable: null,
                                        default: (
                                            <div className="flex items-center gap-2">
                                                <span className="badge badge-secondary whitespace-nowrap">built-in</span>
                                                <SiDjango className="w-5 h-5 shrink-0 text-base-content" />
                                                <span className="opacity-75 font-semibold">Django Admin</span>
                                            </div>
                                        )
                                    },
                                    {
                                        feature: <span className="font-semibold">Cookie Consent</span>,
                                        configurable: null,
                                        default: (
                                            <div className="flex items-center gap-2">
                                                <span className="badge badge-secondary whitespace-nowrap">built-in</span>
                                                <BiCookie className="w-5 h-5 shrink-0 text-base-content" />
                                                <span className="opacity-75 font-semibold">GDPR-compliant Cookie Management</span>
                                            </div>
                                        )
                                    }
                                ].map((row, index) => (
                                    <div key={index} className="card bg-base-200">
                                        <div className="card-body p-4">
                                            <div className="font-bold">{row.feature}</div>
                                            {row.configurable && row.configurable.props.children && (
                                                <div className="mt-1">
                                                    {row.configurable}
                                                </div>
                                            )}
                                            <div className="mt-1">
                                                {row.default}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div className="mt-8">
                                <button
                                    onClick={(e) => e.currentTarget.nextElementSibling.classList.toggle('hidden')}
                                    className="w-full bg-base-300 p-4 rounded-lg hover:bg-base-300/80 transition-colors flex items-center justify-between"
                                >
                                    <h4 className="text-xl font-display">How does configuration work?</h4>
                                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                    </svg>
                                </button>
                                <div className="hidden mt-4 bg-base-300 p-6 rounded-lg">
                                    <p className="mb-4">
                                        <strong>Configurable features</strong> are designed to be plug-and-play through <strong>environment variables</strong>.
                                        Just add your credentials, and the system automatically enables the feature:
                                    </p>
                                    <ul className="list-disc list-inside space-y-2">
                                        <li>Database: Set <code className="text-xs bg-base-200 px-2 py-1 rounded">DATABASE_URL=postgres://...</code></li>
                                        <li>Redis caching: Set <code className="text-xs bg-base-200 px-2 py-1 rounded">REDIS_URL=redis://...</code></li>
                                        <li>And similarly for other services...</li>
                                    </ul>
                                    <p className="mt-4 text-base-content/70">
                                        Want to <strong>start small</strong>? No problem — the system automatically <strong>falls back</strong> to simpler alternatives
                                        (like SQLite for database or memory cache for Redis) until you're ready to scale up.
                                    </p>
                                </div>
                            </div>

                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                            <div className="bg-base-100 p-8 rounded-lg">
                                <h3 className="text-2xl font-display mb-6">Full Stack Architecture</h3>
                                <div className="space-y-3">
                                    <div className="flex items-center gap-3">
                                        <SiReact className="w-5 h-5 shrink-0 text-base-content" />
                                        <span className="text-base-content font-semibold">Independent frontend codebase with React (Create React App)</span>
                                    </div>

                                    <div className="flex items-center gap-3">
                                        <SiDjango className="w-5 h-5 shrink-0 text-base-content" />
                                        <span className="text-base-content font-semibold">Communication with backend via REST API built with <a href="https://www.django-rest-framework.org/" target="_blank" rel="noopener noreferrer" className="text-primary hover:text-primary-focus">Django Rest Framework</a></span>
                                    </div>

                                    <div className="flex items-center gap-3">
                                        <SiTailwindcss className="w-5 h-5 shrink-0 text-base-content" />
                                        <span className="text-base-content font-semibold">Modern styling with <a href="https://tailwindcss.com/" target="_blank" rel="noopener noreferrer" className="text-primary hover:text-primary-focus">Tailwind</a> & <a href="https://daisyui.com/" target="_blank" rel="noopener noreferrer" className="text-primary hover:text-primary-focus">DaisyUI</a></span>
                                    </div>

                                    <div className="flex items-center gap-3">
                                        <SiReact className="w-5 h-5 shrink-0 text-base-content" />
                                        <span className="text-base-content font-semibold">Ready-to-use components: Login, Signup, Profile, Pricing, Payment, etc.</span>
                                    </div>

                                    <div className="flex items-center gap-3">
                                        <SiReact className="w-5 h-5 shrink-0 text-base-content" />
                                        <span className="text-base-content font-semibold">Ready-to-use API client. Easy to add your own.</span>
                                    </div>
                                </div>

                                <div className="mt-6 text-sm italic text-base-content/70 border-t border-base-300 pt-4">
                                    Note: You can absolutely build your own frontend with any framework (Vue, Angular, etc.) —
                                    the Django backend is framework-agnostic and communicates via REST API.
                                </div>
                            </div>

                            <div className="bg-base-100 p-8 rounded-lg">
                                <h3 className="text-2xl font-display mb-6">Build & Deploy in Minutes 🚀</h3>
                                <div className="space-y-3">
                                    <div className="flex items-center gap-3">
                                        <SiDjango className="w-5 h-5 shrink-0 text-base-content" />
                                        <span className="text-base-content font-semibold">Frontend is seamlessly built and served by Django</span>
                                    </div>

                                    <div className="flex items-center gap-3">
                                        <SiRailway className="w-5 h-5 shrink-0 text-base-content" />
                                        <span className="text-base-content font-semibold">
                                            <a href="https://railway.app/" target="_blank" rel="noopener noreferrer" className="text-primary hover:text-primary-focus">Railway</a> deployment support: Dockerfile, Procfile
                                        </span>
                                    </div>

                                    <div className="flex items-center gap-3">
                                        <SiRender className="w-5 h-5 shrink-0 text-base-content" />
                                        <span className="text-base-content font-semibold">
                                            <a href="https://render.com/" target="_blank" rel="noopener noreferrer" className="text-primary hover:text-primary-focus">Render</a> deployment support: build script, blueprint
                                        </span>
                                    </div>

                                    <div className="flex items-center gap-3">
                                        <TbFileDescription className="w-5 h-5 shrink-0 text-base-content" />
                                        <span className="text-base-content font-semibold">Well-documented instructions and guidelines</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="text-center">
                    <h3 className="text-2xl font-bold mb-4">Ready to ship your new web app?</h3>
                    <Link
                        to="/pricing"
                        className="btn btn-primary btn-lg"
                    >
                        Start Building Now
                    </Link>
                </section>
            </main>
        </div>
    );
};

export default LandingPage;
