import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { initiatePasswordReset } from '../client/authService';

const PasswordResetRequest = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage('');
        setError('');

        try {
            const response = await initiatePasswordReset(email);
            setMessage(response.message);
        } catch (err) {
            setError(err.response?.data?.email?.[0] || 'An error occurred. Please try again.');
        }
    };

    return (
        <>
            <Helmet>
                <title>Reset Password | {process.env.REACT_APP_SITE_NAME}</title>
                <meta name="description" content="Securely reset your Django ShipFast password. We'll guide you through our secure password recovery process to help you regain access to your account." />
                <meta name="keywords" content="Django ShipFast password reset, secure password recovery, account recovery, forgot password" />
                <link rel="canonical" href={`https://${process.env.REACT_APP_SITE_DOMAIN}/reset-password`} />
            </Helmet>
            <div className="bg-base-200 flex items-center justify-center min-h-screen">
                <div className="card w-96 bg-base-100 shadow-xl">
                    <div className="card-body">
                        <h2 className="card-title text-2xl font-bold mb-6">Reset Password</h2>
                        {message && <div className="alert alert-success">{message}</div>}
                        {error && <div className="alert alert-error">{error}</div>}
                        <form onSubmit={handleSubmit}>
                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">Email</span>
                                </label>
                                <label className="input input-bordered flex items-center gap-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4 opacity-70"><path d="M2.5 3A1.5 1.5 0 0 0 1 4.5v.793c.026.009.051.02.076.032L7.674 8.51c.206.1.446.1.652 0l6.598-3.185A.755.755 0 0 1 15 5.293V4.5A1.5 1.5 0 0 0 13.5 3h-11Z" /><path d="M15 6.954 8.978 9.86a2.25 2.25 0 0 1-1.956 0L1 6.954V11.5A1.5 1.5 0 0 0 2.5 13h11a1.5 1.5 0 0 0 1.5-1.5V6.954Z" /></svg>
                                    <input
                                        type="email"
                                        className="grow"
                                        placeholder="email@example.com"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </label>
                            </div>
                            <div className="form-control mt-6">
                                <button type="submit" className="btn btn-primary">
                                    Send Reset Link
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PasswordResetRequest;
